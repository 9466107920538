@import url('https://fonts.googleapis.com/css?family=Exo:400,600,700|Roboto:400,600,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');
// themes - our custom or/and out of the box themes
@import 'themes';
@import 'variables';
@import 'bootstrap/scss/bootstrap.scss';

// framework component themes (styles tied to theme variables)
// @import 'bootstrap/scss/bootstrap';
@import '@nebular/bootstrap/styles/globals';
@import '@nebular/theme/styles/globals';
// ...

// global app font size
@import './font-size';

// loading progress bar theme
@import './pace.theme';
@import './pagination.theme';

@import './bootstrap-rtl';

// install the framework and custom global styles
@include nb-install() {
  // framework global styles
  @include nb-theme-global();
  @include nb-bootstrap-global();

  // loading progress bar
  @include ngx-pace-theme();

  @include ngx-pagination-theme();

  @include bootstrap-rtl();

  .btn.btn-outline-primary:focus,
  .btn.btn-outline-primary.focus {
    color: #2a2a2a;
  }

  .btn.btn-outline-primary:hover,
  .btn.btn-outline-primary.hover,
  .btn.btn-outline-primary:active,
  .btn.btn-outline-primary.active {
    color: #ffffff;
  }

  a,
  a:hover,
  a:focus,
  a:active {
    color: nb-theme(color-primary-500);
    text-decoration: none;
  }
}

.linePdf {
  width: 100%;
  height: 1px;
  background: red;
  position: absolute;
  z-index: 1;
  left: 0;
}

ngb-modal-backdrop {
  z-index: 1040 !important;
}

.h1-section {
  color: #40b4e5;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 40px 0px 40px 0px;
  nb-icon {
    font-size: 2.6rem;
    margin-right: 5px;
  }
}
